import React,{useEffect, useState, useRef} from 'react'
import html2canvas from 'html2canvas';
import Cropper from 'react-easy-crop'
import 'react-medium-image-zoom/dist/styles.css'



import logoapodd from './image/LOGO-APODD.png'
import img1 from './image/1.png'
import img2 from './image/2.png'
import img3 from './image/3.png'
import img4 from './image/4.png'
import img5 from './image/5.png'
import img6 from './image/6.png'
import img7 from './image/7.png'
import img8 from './image/8.png'
import img9 from './image/9.png'
import img10 from './image/10.png'
import img11 from './image/11.png'
import img12 from './image/12.png'
import img13 from './image/13.png'
import img14 from './image/14.png'
import img15 from './image/15.png'
import img16 from './image/16.png'
import img17 from './image/17.png'

import eimg1 from './image/empty/1.png'
import eimg2 from './image/empty/2.png'
import eimg3 from './image/empty/3.png'
import eimg4 from './image/empty/4.png'
import eimg5 from './image/empty/5.png'
import eimg6 from './image/empty/6.png'
import eimg7 from './image/empty/7.png'
import eimg8 from './image/empty/8.png'
import eimg9 from './image/empty/9.png'
import eimg10 from './image/empty/10.png'
import eimg11 from './image/empty/11.png'
import eimg12 from './image/empty/12.png'
import eimg13 from './image/empty/13.png'
import eimg14 from './image/empty/14.png'
import eimg15 from './image/empty/15.png'
import eimg16 from './image/empty/16.png'
import eimg17 from './image/empty/17.png'

import Illustration from '../assets/odd-illustration.jpg'


const Home = () => {

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)

    const [oddcheck, setOddcheck] = useState({
        odd1: false,
        odd2: false,
        odd3: false,
        odd4: false,
        odd5: false,
        odd6: false,
        odd7: false,
        odd8: false,
        odd9: false,
        odd10: false,
        odd11: false,
        odd12: false,
        odd13: false,
        odd14: false,
        odd15: false,
        odd16: false,
        odd17: false
    });

    

    const [showRoue, setShowRoue] = useState(false)
    const [min, setMin] = useState(0)

    const handleIncrement = (etat) => {
        if((min<3) || (min===3 && !etat)) {
            if(etat) setMin( prevState => prevState + 1);
            else setMin(prevState => prevState - 1)
        }
    }

    const handleCheckBox = (e) => {
        var tmp = e.target.name
        var statucheck = e.target.checked

        handleIncrement(statucheck);

        if((min < 3) || (min === 3 && !statucheck)) {
            setOddcheck(prevState => {
                if(tmp in prevState) return {...prevState, [tmp]: !prevState[tmp]}
                else return prevState
            })
        }
        
    }

    const [imageRoue, setImageRoue] = useState([
        'image/empty/1.png',
        'image/empty/2.png',
        'image/empty/3.png',
        'image/empty/4.png',
        'image/empty/5.png',
        'image/empty/6.png',
        'image/empty/7.png',
        'image/empty/8.png',
        'image/empty/9.png',
        'image/empty/10.png',
        'image/empty/11.png',
        'image/empty/12.png',
        'image/empty/13.png',
        'image/empty/14.png',
        'image/empty/15.png',
        'image/empty/16.png',
        'image/empty/17.png'
    ])

    const [oddCheckArray2, setoddCheckArray2] = useState([])

    const [selectedImage, setSelectedImage] = useState(null);


    const [generatedImage, setGeneratedImage] = useState(null);
    const divRef = useRef(null);

    const handleCapture = () => {
        if (divRef.current) {
            const scale = window.devicePixelRatio;
            html2canvas(divRef.current, {scale: 1.5}).then((canvas) => {
                const dataURL = canvas.toDataURL();
                setGeneratedImage(dataURL);

                const downloadLink = document.createElement('a');
                downloadLink.href = dataURL;
                downloadLink.download = 'odd_badge_image.png';

                downloadLink.click();
            });
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Utilisez FileReader pour lire le fichier image et mettre à jour l'état avec l'URL de l'image
            const reader = new FileReader();

            reader.onloadend = () => {
                setSelectedImage(reader.result);
                setCroppedImageUrl(null)
            };

            reader.readAsDataURL(file);
        }
    };

    // useEffect(()=>{
    //     console.log(oddcheck)
    // },[oddcheck])

    useEffect(()=>{
        // console.log(imageRoue)
    },[imageRoue])

    const generateRoue = () => {
        
        const oddCheckArray = Object.values(oddcheck);
        setoddCheckArray2(oddCheckArray)
        // oddCheckArray.forEach()

        // console.log(oddcheck[0])
        // console.log(oddCheckArray)

        for (let i = 0; i <= oddCheckArray.length; i++) {
            
            if(oddCheckArray[i]) {
                
                setImageRoue((prevImage) => {
                    return [...prevImage, `image/${i+1}.png`];
                })

                setImageRoue((prevImage) => 
                    prevImage.filter(image => image !== `image/empty/${i+1}.png`)
                )   
            }
        }

        if(min>=1) setShowRoue(true)
    }

    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);

    const getCroppedImg = (imageSrc, crop) => {
        return new Promise((resolve, reject) => {
          // Créer un élément image pour charger l'image source
          const image = new Image();
          image.src = imageSrc;
      
          // Charger l'image avant de traiter la découpe
          image.onload = () => {
            // Créer un élément canvas pour dessiner l'image rognée
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
      
            // Définir la largeur et la hauteur du canvas en fonction de la zone rognée
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
      
            // Dessiner l'image rognée sur le canvas
            ctx.drawImage(
              image,
              crop.x * scaleX,
              crop.y * scaleY,
              crop.width * scaleX,
              crop.height * scaleY,
              0,
              0,
              crop.width,
              crop.height
            );
      
            // Obtenir l'URL de l'image rognée à partir du canvas
            canvas.toBlob(
              (blob) => {
                const croppedImageUrl = URL.createObjectURL(blob);
                resolve(croppedImageUrl);
              },
              'image/jpeg', // Format de l'image rognée
              1 // Qualité de l'image rognée (0-1)
            );
          };
      
          // Gérer les erreurs de chargement de l'image
          image.onerror = (error) => {
            reject(error);
          };
        });
    };

    const onCropComplete = async (croppedArea, croppedAreaPixels) => {
        try {
          const croppedImage = await getCroppedImg(selectedImage, croppedAreaPixels);
          setCroppedImageUrl(croppedImage);
        } catch (error) {
          console.error('Error cropping image:', error);
        }
    };

    const handleCrop = async () => {
        // const croppedImage = await getCroppedImg(selectedImage, croppedAreaPixels);
        setSelectedImage(null)
    };
    
    // const cc = {backgroundImage: `url(${process.env.PUBLIC_URL + "/image/empty/1.png"}), url(${process.env.PUBLIC_URL + "/image/empty/2.png"}), url(${process.env.PUBLIC_URL + "/image/empty/3.png"}), url(${process.env.PUBLIC_URL + "/image/empty/4.png"}), url(${process.env.PUBLIC_URL + "/image/empty/5.png"}), url(${process.env.PUBLIC_URL + "/image/empty/6.png"}), url(${process.env.PUBLIC_URL + "/image/empty/7.png"}), url(${process.env.PUBLIC_URL + "/image/empty/8.png"}), url(${process.env.PUBLIC_URL + "/image/empty/9.png"}), url(${process.env.PUBLIC_URL + "/image/empty/10.png"}), url(${process.env.PUBLIC_URL + "/image/empty/11.png"}), url(${process.env.PUBLIC_URL + "/image/empty/12.png"}), url(${process.env.PUBLIC_URL + "/image/empty/13.png"}), url(${process.env.PUBLIC_URL + "/image/empty/14.png"}), url(${process.env.PUBLIC_URL + "/image/empty/15.png"}), url(${process.env.PUBLIC_URL + "/image/empty/16.png"}), url(${process.env.PUBLIC_URL + "/image/empty/17.png"})`};
    
    return (
        <div className='h-screen'>
            <div className='bg-white drop-shadow p-4 flex justify-center'>
                <img src={logoapodd} width={200} />
            </div>
            {selectedImage && (
                <div className='absolute top-0 left-0 w-full h-full bg-black/80 flex justify-center items-center z-20'>
                    <div className=''>
                        <Cropper
                            image={selectedImage}
                            crop={crop}
                            zoom={zoom}
                            cropShape='round'
                            cropSize={{ width: 200, height: 200 }}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            aspect={4 / 3}
                            onCropComplete={onCropComplete}
                        />
                    </div>
                    {/* {croppedImageUrl && <img src={croppedImageUrl} alt="Cropped" />} */}
                    <div className='w-full gap-x-5 flex justify-center absolute flex-wrap z-50 bottom-10 left-0'>
                        <button className='bg-green-500 text-white mb-4 p-3 px-10 rounded-full' onClick={handleCrop}>Valider</button>
                        <button className='bg-red-500 text-white mb-4 p-3 px-10 rounded-full' onClick={handleCrop}>Fermer</button>
                    </div>
                </div>
                )
            }

            {
                showRoue ?
                <div className='h-full flex flex-col items-center justify-center relative'>
                    
                    <div className='w-[400px] h-[400px] flex items-center justify-center relative rounded-full' ref={divRef}>
                            
                            {croppedImageUrl && (
                                <div className='w-[200px] h-[200px] bg-red-500 rounded-full'>
                                    <img src={croppedImageUrl} alt="Selected" className='rounded-full w-[200px] h-[200px]'   />

                                </div>
                            )}
                            
                            {oddCheckArray2[0] ? <img src={img1} className='rounded-full w-[400px] h-[400px] absolute top-0'/> : <img src={eimg1} className='rounded-full w-[400px] h-[400px] absolute top-0'/> }
                            {oddCheckArray2[1] ? <img src={img2} className='rounded-full w-[400px] h-[400px] absolute top-0'/> : <img src={eimg2} className='rounded-full w-[400px] h-[400px] absolute top-0'/> }
                            {oddCheckArray2[2] ? <img src={img3} className='rounded-full w-[400px] h-[400px] absolute top-0'/>: <img src={eimg3} className='rounded-full w-[400px] h-[400px] absolute top-0'/> }
                            {oddCheckArray2[3] ? <img src={img4} className='rounded-full w-[400px] h-[400px] absolute top-0'/>: <img src={eimg4} className='rounded-full w-[400px] h-[400px] absolute top-0'/> }
                            {oddCheckArray2[4] ? <img src={img5} className='rounded-full w-[400px] h-[400px] absolute top-0'/>: <img src={eimg5} className='rounded-full w-[400px] h-[400px] absolute top-0'/> }
                            {oddCheckArray2[5] ? <img src={img6} className='rounded-full w-[400px] h-[400px] absolute top-0'/>: <img src={eimg6} className='rounded-full w-[400px] h-[400px] absolute top-0'/> }
                            {oddCheckArray2[6] ? <img src={img7} className='rounded-full w-[400px] h-[400px] absolute top-0'/>: <img src={eimg7} className='rounded-full w-[400px] h-[400px] absolute top-0'/> }
                            {oddCheckArray2[7] ? <img src={img8} className='rounded-full w-[400px] h-[400px] absolute top-0'/>: <img src={eimg8} className='rounded-full w-[400px] h-[400px] absolute top-0'/> }
                            {oddCheckArray2[8] ? <img src={img9} className='rounded-full w-[400px] h-[400px] absolute top-0'/>: <img src={eimg9} className='rounded-full w-[400px] h-[400px] absolute top-0'/> }
                            {oddCheckArray2[9] ? <img src={img10} className='rounded-full w-[400px] h-[400px] absolute top-0'/>: <img src={eimg10} className='rounded-full w-[400px] h-[400px] absolute top-0'/> }
                            {oddCheckArray2[10] ? <img src={img11} className='rounded-full w-[400px] h-[400px] absolute top-0'/>: <img src={eimg11} className='rounded-full w-[400px] h-[400px] absolute top-0'/> }
                            {oddCheckArray2[11] ? <img src={img12} className='rounded-full w-[400px] h-[400px] absolute top-0'/>: <img src={eimg12} className='rounded-full w-[400px] h-[400px] absolute top-0'/> }
                            {oddCheckArray2[12] ? <img src={img13} className='rounded-full w-[400px] h-[400px] absolute top-0'/>: <img src={eimg13} className='rounded-full w-[400px] h-[400px] absolute top-0'/> }
                            {oddCheckArray2[13] ? <img src={img14} className='rounded-full w-[400px] h-[400px] absolute top-0'/>: <img src={eimg14} className='rounded-full w-[400px] h-[400px] absolute top-0'/> }
                            {oddCheckArray2[14] ? <img src={img15} className='rounded-full w-[400px] h-[400px] absolute top-0'/>: <img src={eimg15} className='rounded-full w-[400px] h-[400px] absolute top-0'/> }
                            {oddCheckArray2[15] ? <img src={img16} className='rounded-full w-[400px] h-[400px] absolute top-0'/>: <img src={eimg16} className='rounded-full w-[400px] h-[400px] absolute top-0'/> }
                            {oddCheckArray2[16] ? <img src={img17} className='rounded-full w-[400px] h-[400px] absolute top-0'/>: <img src={eimg17} className='rounded-full w-[400px] h-[400px] absolute top-0'/> }
                                

                            <div className='absolute right-0 bottom-1 flex items-center'>
                                <img src={logoapodd} alt='APODD LOGO' width={95} />
                            </div>
                        </div>
                    
                    <input type="file" className='block text-sm text-slate-500
                        file:mr-4 file:py-2 file:px-4 file:rounded-full
                        file:border-0 file:text-sm file:font-semibold
                        file:bg-blue-500 file:text-white
                        hover:file:bg-blue-400 mt-5' 
                        onChange={handleImageChange} 
                        accept="image/*" 
                        style={{ display: 'none' }}
                        id='fileInput'
                    />
                    
                    <div className='flex flex-col items-center justify-center gap-y-2'>
                        {croppedImageUrl && (<button className='bg-green-500 text-white py-4 px-4 font-semibold rounded-full text-sm block' onClick={handleCapture}>Générer mon Badge</button>)}
                        <label htmlFor="fileInput" className='block text-sm
                            py-4 px-4 rounded-full
                            border-0 font-semibold
                            bg-slate-500 text-white
                            hover:bg-slate-400 cursor-pointer'>
                            Choisir une image
                        </label>
                    </div>

                    {/* {generatedImage && (
                        <div>
                        <p>Image générée :</p>
                        <img src={generatedImage} alt="Generated" style={{ width: '250px', height: '250px' }} />
                        </div>
                    )} */}

                    <footer className='w-screen p-2 bg-black/45 flex flex-col items-center absolute bottom-0'>
                        <p className='text-sm w-full flex justify-center text-white'> &copy; Copyright 2024 by <span className='font-bold pl-1'>APODD</span></p>
                        <p className='text-white font-bold text-sm'>Made with <span className=' text-red-600'>❤</span> by NerdX Digital</p>
                    </footer>
                </div>
                :
                <div className=' relative'>
                    
                    <div className='flex lg:flex-row flex-col items-center'>
                        <div className='flex flex-col lg pt-10 md:pt-24 pb-10 p-4 md:pl-8 lg:w-8/12'>
                            <h1 className='text-3xl md:text-5xl text-[#121751] font-bold pl-4'>Devenir Ambassadeur ODD</h1>
                            <div className=' md:mt-10 p-4  md:w-10/12 leading-relaxed'>
                                <p className='mb-2'>Rejoignez la révolution de la communication responsable avec la Roue ODD. Choisissez vos trois ODD préférés, personnalisez votre roue en chargeant votre photo et devenez un acteur clé de l'Agenda 2030 !</p>
                                <p className='mb-2'>Plus de 10.000 ambassadeurs dans 27 pays agissent déjà pour un avenir durable. Et vous, quel impact souhaitez-vous avoir ? Rejoignez-nous et faites la différence ! </p>
                                <p className='mb-2'>Cochez vos trois ODD, cliquez sur Je choisis et lancez-vous dès maintenant ! </p>
                            </div>
                        </div>
                        <div className='flex flex-row justify-center items-center self-stretch lg:w-4/12 lg:pt-20'>
                            <img src={Illustration} className='lg:w-2/3 w-1/2' />
                        </div>
                    </div>
                    <div className="mb-20 mt-2 md:mt-10 md:px-8">
                        
                        <h2 className="font-mono mb-3 uppercase font-bold text-2xl pl-3">Choisissez vos ODD</h2>
                        <div className="font-sans">

                            <div className="flex md:flex-row flex-col">
                                <div className="mb-3">
                                    <label htmlFor="odd1" className="relative flex items-center p-3 rounded-full cursor-pointer"><input type="checkbox" checked={oddcheck.odd1} onChange={handleCheckBox} id="odd1" name="odd1" className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#444] checked:bg-[#444] checked:before:bg-[#444] hover:before:opacity-10 mr-3" /> Pas de pauvreté</label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="odd2" className="relative flex items-center p-3 rounded-full cursor-pointer"><input type="checkbox" checked={oddcheck.odd2} id="odd2" name="odd2" onChange={handleCheckBox} className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#444] checked:bg-[#444] checked:before:bg-[#444] hover:before:opacity-10 mr-3" /> Faim zéro</label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="odd3" className="relative flex items-center p-3 rounded-full cursor-pointer"><input type="checkbox" checked={oddcheck.odd3} id="odd3" name="odd3" onChange={handleCheckBox} className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#444] checked:bg-[#444] checked:before:bg-[#444] hover:before:opacity-10 mr-3" /> Bonne santé et bien-être </label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="odd4" className="relative flex items-center p-3 rounded-full cursor-pointer"><input type="checkbox" checked={oddcheck.odd4} id="odd4" name="odd4" onChange={handleCheckBox} className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#444] checked:bg-[#444] checked:before:bg-[#444] hover:before:opacity-10 mr-3" /> Education de qualité</label>
                                </div>
                            </div>

                            <div className="flex md:flex-row flex-col">
                                <div className="mb-3">
                                    <label htmlFor="odd5" className="relative flex items-center p-3 rounded-full cursor-pointer"><input type="checkbox" checked={oddcheck.odd5} id="odd5" name="odd5" onChange={handleCheckBox} className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#444] checked:bg-[#444] checked:before:bg-[#444] hover:before:opacity-10 mr-3" /> Egalité entre les sexes</label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="odd6" className="relative flex items-center p-3 rounded-full cursor-pointer"><input type="checkbox" checked={oddcheck.odd6} id="odd6" name="odd6" onChange={handleCheckBox} className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#444] checked:bg-[#444] checked:before:bg-[#444] hover:before:opacity-10 mr-3" /> Eau propre et assainissement</label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="odd7" className="relative flex items-center p-3 rounded-full cursor-pointer"><input type="checkbox" checked={oddcheck.odd7} id="odd7" name="odd7" onChange={handleCheckBox} className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#444] checked:bg-[#444] checked:before:bg-[#444] hover:before:opacity-10 mr-3" /> Energie propre et d'un coup abordable</label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="odd8" className="relative flex items-center p-3 rounded-full cursor-pointer"><input type="checkbox" checked={oddcheck.odd8} id="odd8" name="odd8" onChange={handleCheckBox} className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#444] checked:bg-[#444] checked:before:bg-[#444] hover:before:opacity-10 mr-3" /> Travail décent et croissance économique</label>
                                </div>
                            </div>

                            <div className="flex md:flex-row flex-col">
                                <div className="mb-3">
                                    <label htmlFor="odd9" className="relative flex items-center p-3 rounded-full cursor-pointer"><input type="checkbox" checked={oddcheck.odd9}  id="odd9" name="odd9" onChange={handleCheckBox} className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#444] checked:bg-[#444] checked:before:bg-[#444] hover:before:opacity-10 mr-3" /> Industrie, innovation et infrastructure</label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="odd10" className="relative flex items-center p-3 rounded-full cursor-pointer"><input type="checkbox" checked={oddcheck.odd10} id="odd10" name="odd10" onChange={handleCheckBox} className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#444] checked:bg-[#444] checked:before:bg-[#444] hover:before:opacity-10 mr-3" /> Inégalités réduites</label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="odd11" className="relative flex items-center p-3 rounded-full cursor-pointer"><input type="checkbox" checked={oddcheck.odd11} id="odd11" name="odd11" onChange={handleCheckBox} className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#444] checked:bg-[#444] checked:before:bg-[#444] hover:before:opacity-10 mr-3" /> Villes et communautés durable</label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="odd12" className="relative flex items-center p-3 rounded-full cursor-pointer"><input type="checkbox" checked={oddcheck.odd12} id="odd12" name="odd12" onChange={handleCheckBox} className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#444] checked:bg-[#444] checked:before:bg-[#444] hover:before:opacity-10 mr-3" /> Consommation et production durables</label>
                                </div>
                            </div>

                            <div className="flex md:flex-row flex-col">
                                <div className="mb-3">
                                    <label htmlFor="odd13" className="relative flex items-center p-3 rounded-full cursor-pointer"><input type="checkbox" checked={oddcheck.odd13} id="odd13" name="odd13" onChange={handleCheckBox} className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#444] checked:bg-[#444] checked:before:bg-[#444] hover:before:opacity-10 mr-3" /> Mesures relatives à la lutte contre les changements climatiques</label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="odd14" className="relative flex items-center p-3 rounded-full cursor-pointer"><input type="checkbox" checked={oddcheck.odd14} id="odd14" name="odd14" onChange={handleCheckBox} className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#444] checked:bg-[#444] checked:before:bg-[#444] hover:before:opacity-10 mr-3" /> Vie aquatique</label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="odd15" className="relative flex items-center p-3 rounded-full cursor-pointer"><input type="checkbox" checked={oddcheck.odd15} id="odd15" name="odd15" onChange={handleCheckBox} className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#444] checked:bg-[#444] checked:before:bg-[#444] hover:before:opacity-10 mr-3" /> Vie terrestre</label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="odd16" className="relative flex items-center p-3 rounded-full cursor-pointer"><input type="checkbox" checked={oddcheck.odd16} id="odd16" name="odd16" onChange={handleCheckBox} className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#444] checked:bg-[#444] checked:before:bg-[#444] hover:before:opacity-10 mr-3" /> Paix, Justice et Institutions efficaces</label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="odd17" className="relative flex items-center p-3 rounded-full cursor-pointer"><input type="checkbox" checked={oddcheck.odd17} id="odd17" name="odd17" onChange={handleCheckBox} className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#444] checked:bg-[#444] checked:before:bg-[#444] hover:before:opacity-10 mr-3" /> Partenariats pour la réalisation des objectifs</label>
                                </div>
                            </div>

                            <div className="my-3 mb-5 ml-4">
                                <button onClick={()=>generateRoue()} className="text-white bg-blue-600 rounded-md py-2 px-4 shadow-xl">Je choisis</button>
                            </div>

                        </div>
                    </div>
                    <footer className='w-full p-2 bg-black/45 flex flex-col items-center'>
                        <p className='text-sm w-full flex justify-center text-white'> &copy; Copyright 2024 by <span className='font-bold pl-1'>APODD</span></p>
                        <p className='text-white font-bold text-sm'>Made with <span className=' text-red-600'>❤</span> by NerdX Digital</p>
                    </footer>
                </div>
                
            }
            
        </div>
    )
}

export default Home